import getLocalizedPeriod from '@/mixins/getLocalizedPeriod';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
import { StackTariff } from '@/models/BillMgr/StackTariff';
import { SaasTariff } from '@/models/BillMgr/SaasTariff';
// import { moduleMain } from '@/utils/getServices';
export default {
  mixins: [getLocalizedPeriod],
  data() {
    return {
      payments: '',
      ap_actions: {
        on: this.ap_edit,
        off: this.ap_off,
        update: this.ap_edit,
      },
      ap_modal: null,
      ap_tariff: null,
      ap_currentPeriod: '',
      ap_list: [],
    };
  },
  computed: {
    ap_currentValue() {
      return this.ap_list.find(i => i.k === this.ap_currentPeriod) || null;
    },
    // moduleMain() {
    //   return moduleMain[this.tariff.itemtype];
    // },
    tools() {
      // console.log(this.moduleMain);
      if (this.tariff instanceof StackTariff) return this.$store.state.moduleSaas.toolsDeployMe;
      if (this.tariff instanceof SaasTariff) return this.$store.state.moduleSaas.toolsGitFlic;
      const splitted = this.moduleMain.split('.');
      const path = splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
      // console.log(path);
      return path.tools;
    },
    moduleMainPath() {
      return this.moduleMain.replaceAll('.', '/');
    },
    storedMethod() {
      // console.log(this.ap_tariff ? this.ap_tariff.stored_method : null);
      return this.ap_tariff ? this.ap_tariff.stored_method : null;
    },
  },
  mounted() {
    // console.log('mount', this.tariff);
  },
  methods: {
    autoprolong(tariff, action) {
      // console.log(this.storedMethod);
      if (
        tariff &&
        tariff.autoprolongSettings &&
        this.tools.edit &&
        this.tools.edit.isEnable(tariff) &&
        this.ap_actions[action]
      ) {
        this.ap_tariff = tariff;
        // console.log('tariuff', tariff);
        this.ap_actions[action](tariff, action);
        // console.log(this.ap_actions[action](tariff, action));
      }
    },
    // actions
    ap_edit(tariff, action) {
      // console.log(this.storedMethod);
      const settings = tariff.autoprolongSettings;
      this.ap_currentPeriod = settings.value;
      this.ap_list = settings.list.map(i => {
        return {
          ...i,
          cost: i.cost ? parseFloat(i.cost) : 0,
          label: i.k === 'null' ? i.v : this.getLocalizedPeriod(i.k),
        };
      });
      this.$modals.open({
        name: 'AutoprolongEdit',
        // title: this.$t(`autoprolong.modals.${action}.title`),
        component: this.singleProlongComponent,
        size: 'big',
        props: {
          tariff,
          list: this.ap_list,
          value: this.ap_currentValue,
          // title: this.$t('autoprolong.title'),
        },
        on: {
          change: value => {
            // console.log(value);
            this.ap_currentPeriod = value.k;
          },
          payments: value => (this.payments = value),
        },
        onOpen: inst => (this.ap_modal = inst),
        onClose: () => {
          this.ap_modal = null;
          this.ap_reset();
        },
        footer: {
          confirm: {
            // props: { title: this.$t(`autoprolong.modals.${action}.confirm`) },
            on: {
              click: () => {
                // console.log(this.payments);
                // console.log(tariff);
                // console.log(this.ap_currentPeriod);
                this.ap_sendEdit(tariff, this.ap_currentPeriod, this.payments);
                this.ap_sendEdit(tariff, this.ap_currentPeriod);
              },
            },
          },
          cancel: {
            on: {
              click: () => this.$modals.close(),
            },
          },
        },
      });
    },
    ap_off(tariff) {
      // console.log(this.storedMethod);
      this.$modals.open({
        name: 'AutoprolongOff',
        // title: this.$t('autoprolong.modals.off.title'),
        text: this.$t('autoprolong.modals.off.desc', { id: tariff.id }),
        onOpen: inst => (this.ap_modal = inst),
        onClose: () => {
          this.ap_modal = null;
          this.ap_reset();
        },
        footer: {
          confirm: {
            // props: { title: this.$t('autoprolong.modals.off.confirm') },
            on: {
              click: () => {
                this.ap_sendEdit(tariff, 'null');
              },
            },
          },
          cancel: {
            on: {
              click: () => this.$modals.close(),
            },
          },
        },
      });
    },
    ap_sendEdit(tariff, value) {
      // console.log(this.storedMethod);
      // console.log(payments, this.tariff.stored_method);
      const params = {
        elid: tariff.id,
        autoprolong: value,
        // stored_method: this.storedMethod ? this.storedMethod : null,
        // func: this.tools.edit.func,
        func:
          this.tools && this.tools.edit && this.tools.edit.func
            ? this.tools.edit.func
            : `${this.tariff.itemtype}.edit`,
      };
      // console.log(this.payments);
      if (this.payments) params.stored_method = this.payments;
      let modal = null;
      this.$modals.open({
        name: 'AutoprolongResult',
        // title: this.$t('sending'),
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      let result = '';
      // console.log(tariff);
      this.$store
        // .dispatch('moduleAll/fetchItemDetail', { id: tariff.id, item: tariff.itemtype })
        .dispatch(`${this.moduleMainPath}/updateDetail`, params)
        .then(async data => {
          result = data && data.ok ? 'success' : 'error';
          if (result === 'success') {
            if (tariff.itemtype) {
              this.$store
                .dispatch('moduleAll/fetchItem', { id: tariff.id, item: tariff.itemtype })
                .then(data => {
                  // console.log(data);
                  this.ap_reset();
                });
            } else this.ap_reset();

            // this.$store.dispatch(`${this.moduleMainPath}/updateList`);
            this.moduleMainPath.includes('moduleDomainsDnsHost')
              ? await this.$store.dispatch(`${this.moduleMainPath}/updateDnsList`)
              : await this.$store.dispatch(`${this.moduleMainPath}/updateList`);
            await this.$store.dispatch('moduleBasket/fetchBasket');
            await this.$store.dispatch('moduleProfile/setProfileInfo');
            if (this.ap_modal?.name) this.$modals.close({ name: this.ap_modal.name });
          }
        })
        .catch(e => {
          result = 'error';
        })
        .finally(() => {
          // Vue.set(modal, 'title', this.$t(`autoprolong.result.${result}.title`));
          Vue.set(modal, 'text', this.$t(`autoprolong.result.${result}.desc`, { id: tariff.id }));
          Vue.set(modal, 'component', null);
          Vue.set(modal, 'closable', true);
          Vue.set(modal, 'footer', {
            confirm: {
              // props: { title: this.$t(`autoprolong.result.${result}.confirm`) },
              on: {
                click: () => {
                  this.$modals.close();
                },
              },
            },
          });
        });
    },
    ap_reset() {
      this.ap_currentPeriod = '';
      this.ap_list = [];
      this.ap_tariff = null;
    },
  },
};
