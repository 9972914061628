/**
 * Reformat/recalc size in mb, 'МБ' and other ugly units to mb/gb
 * @param {number} value
 * @param {string} unit - ugly nonstandart string
 * @returns {object} { value: number, unit: mb|gb }
 */
export function parseSizeGb(value, unit) {
  const GB = 1024;
  let num = value;
  let defUnit = value >= GB ? 'mb' : 'gb';
  let unitKey = unit ? (unit === 'МБ' ? 'mb' : unit) : defUnit;
  if (unitKey === 'mb' && value >= GB) {
    unitKey = 'gb';
    num = num / GB;
  }
  return { value: num, unit: unitKey };
}

import { isNaN } from 'lodash';
/**
 * K formatter
 * @param value
 * @param multiplier
 * @param unit
 * @param maxLevels
 * @returns {Array}
 */
export function kFormatter(value, multiplier, unit, maxLevels) {
  if (typeof unit === 'undefined') {
    unit = '';
  }
  if (typeof value !== 'number' && !isNaN(parseFloat(value))) value = parseFloat(value);

  let labels = [unit, 'K' + unit, 'M' + unit, 'G' + unit, 'T' + unit],
    max = typeof maxLevels === 'undefined' ? labels.length : maxLevels;

  for (let i = 0; i < labels.length; i++) {
    if (value <= multiplier || i + 1 >= max) {
      if (value % 1 === 0) {
        return value.toFixed() + labels[i];
      }
      return value.toFixed(2) + labels[i];
    }
    value = value / multiplier;
  }

  return value.toFixed() + labels[labels.length - 1];
}
