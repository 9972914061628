<template>
  <div class="base-pagination">
    <Paginator
      :rows="step"
      :always-show="showPagination"
      :total-records="count"
      :first="start"
      :rows-per-page-options="stepsArray"
      @page="onPage($event)"
    >
    </Paginator>
  </div>
</template>

<script>
import Paginator from 'primevue/paginator';

export default {
  name: 'NewPagination',
  components: { Paginator },
  props: {
    step: {
      type: [Number, String],
      required: true,
    },
    start: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      default: 'base',
    },
    source: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      first: '',
      stepOptions: [10, 25, 50, 100, 1000],
      stepOptionsSmall: [5, 10, 25, 50, 100, 1000],
      stepOptionsBig: [25, 50, 100, 1000],
      stepOptionsFinance: [4, 10, 25, 50, 100, 1000],
    };
  },
  computed: {
    stepsArray() {
      const arr = [];
      let steps = [];
      if (this.type === 'base') {
        steps = this.stepOptions;
      } else if (this.type === 'small') {
        steps = this.stepOptionsSmall;
      } else if (this.type === 'big') {
        steps = this.stepOptionsBig;
      } else steps = this.stepOptionsFinance;

      steps.forEach((x, i) => {
        if (this.count > x) {
          if (!arr.includes(x)) arr.push(x);
          arr.push(steps[i + 1]);
        }
      });
      return arr;
    },
    showPagination() {
      return this.count > this.stepsArray[0];
    },
  },
  watch: {
    stepsArray: {
      handler: function (event) {
        if (event && event.length) this.$emit('set-step', event[0]);
      },
      immediate: true,
    },
  },
  mounted() {
    this.first = this.start || 0;
  },
  methods: {
    name(value) {
      return value === 'all' ? this.$t('all') : value;
    },
    onPage(value) {
      this.$emit('new-pagination', value);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "label": "Показывать на странице",
      "all": "все"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.p-dropdown-label,.p-inputtext {
  min-width: 40px
}
.p-button.p-button-icon-only {
  border-radius: 50%;
}
.base-pagination {
  margin-top: 0.5rem;


  .p-button.p-button-icon-only {
    border-radius: 0;
  }

}
</style>
